import {debounce} from "../_snowpack/pkg/lodash-es.js";
import React, {useCallback, useRef} from "../_snowpack/pkg/react.js";
import {useRecoilValue} from "../_snowpack/pkg/recoil.js";
import {useColoringCanvas} from "./hooks/useColoringCanvas.js";
import {useOnWindowResize} from "./hooks/useOnWindowResize.js";
import {PaletteState} from "./state/pallete.js";
import {getRelativeClickPosition} from "./utils/dom.js";
const Canvas = ({imgUrl}) => {
  const palette = useRecoilValue(PaletteState);
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const {isLoading, loadImage, paint} = useColoringCanvas(containerRef, canvasRef);
  const loadImageCallback = useCallback(debounce(() => loadImage(imgUrl), 300), [imgUrl]);
  useOnWindowResize(loadImageCallback, true);
  const handleClick = (e) => {
    e.stopPropagation();
    const [x, y] = getRelativeClickPosition(e);
    paint(x, y, palette.color.rgb);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, isLoading && /* @__PURE__ */ React.createElement("div", {
    className: "absolute w-full h-full flex items-center justify-center"
  }, "Loading..."), /* @__PURE__ */ React.createElement("div", {
    className: "w-full h-full flex items-center justify-center",
    ref: containerRef,
    onClick: () => paint(0, 0, palette.color.rgb)
  }, /* @__PURE__ */ React.createElement("canvas", {
    ref: canvasRef,
    onClick: (e) => handleClick(e)
  })));
};
export default Canvas;
