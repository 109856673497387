const BLACK_WHITE_THRESHOLD = 155;
export const getImageData = (canvas) => {
  const ctx = canvas.getContext("2d");
  return ctx.getImageData(0, 0, canvas.width, canvas.height);
};
export const putImageData = (canvas, imageData) => {
  const ctx = canvas.getContext("2d");
  return ctx.putImageData(imageData, 0, 0);
};
export const getImageDataIndex = (canvas, x, y) => {
  return y * canvas.width * 4 + x * 4;
};
export const getPosition = (canvas, index) => {
  const y = Math.floor(index / 4 / canvas.width);
  const x = Math.floor(index / 4 - y * canvas.width);
  return [x, y];
};
export const isPixelWhite = (data, index) => {
  return (data[index] + data[index + 1] + data[index + 2]) / 3 >= BLACK_WHITE_THRESHOLD;
};
export const paintPixel = (data, index, color) => {
  data[index] = color[0];
  data[index + 1] = color[1];
  data[index + 2] = color[2];
  data[index + 3] = 255;
};
export const getPixel = (data, index) => [
  ...data.slice(index, index + 3)
];
export const floodFill = async (canvas, index) => {
  const {data} = getImageData(canvas);
  if (index >= data.length || !isPixelWhite(data, index)) {
    return void 0;
  }
  let done;
  const promise = new Promise((resolve) => {
    done = resolve;
  });
  const queue = [index];
  const visited = new Set();
  const cb = () => {
    let iterations = 0;
    while (true) {
      iterations++;
      const nodeIndex = queue.pop();
      visited.add(nodeIndex);
      const [nX, nY] = getPosition(canvas, nodeIndex);
      const nodes = [
        [nX + 1, nY],
        [nX, nY - 1],
        [nX - 1, nY],
        [nX, nY + 1]
      ];
      nodes.map(([x, y]) => getImageDataIndex(canvas, x, y)).filter((n) => !visited.has(n) && n <= data.length && isPixelWhite(data, n)).forEach((n) => {
        queue.push(n);
      });
      if (!queue.length) {
        done(visited);
        return;
      }
      if (iterations > 1e5) {
        requestAnimationFrame(cb);
        return;
      }
    }
  };
  requestAnimationFrame(cb);
  return promise;
};
