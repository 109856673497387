import React from "../_snowpack/pkg/react.js";
import vdeantoni from "../images/vdeantoni.svg.proxy.js";
const MadeBy = (props) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: "absolute bottom-1 right-1"
  }, /* @__PURE__ */ React.createElement("a", {
    className: "block rounded-full bg-white p-1 duration opacity-90 hover:opacity-100",
    title: "Made by vdeantoni.com",
    href: "http://vdeantoni.com",
    target: "_blank"
  }, /* @__PURE__ */ React.createElement("img", {
    className: "w-10 h-10",
    src: vdeantoni,
    alt: "vdeantoni.com"
  })));
};
export default MadeBy;
