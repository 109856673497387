import {faUndoAlt} from "../_snowpack/pkg/@fortawesome/free-solid-svg-icons.js";
import {FontAwesomeIcon} from "../_snowpack/pkg/@fortawesome/react-fontawesome.js";
import classnames from "../_snowpack/pkg/classnames.js";
import {initial} from "../_snowpack/pkg/lodash-es.js";
import React, {useEffect, useRef} from "../_snowpack/pkg/react.js";
import {useRecoilState} from "../_snowpack/pkg/recoil.js";
import {CanvasHistoryState} from "./state/canvas.js";
import {Colors, PaletteState} from "./state/pallete.js";
const colors = [
  Colors.RED,
  Colors.GREEN,
  Colors.BLUE,
  Colors.YELLOW,
  Colors.PURPLE,
  Colors.PINK
];
const angle = 360 / colors.length;
const dist = 60;
const Pallete = ({}) => {
  const [state, setState] = useRecoilState(PaletteState);
  const [history, setHistory] = useRecoilState(CanvasHistoryState);
  const ref = useRef(null);
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const {width, height} = ref.current.getBoundingClientRect();
    const cb = (e) => {
      if (!ref.current) {
        return;
      }
      const x = "clientX" in e ? e.clientX : e.touches[0].clientX;
      const y = "clientY" in e ? e.clientY : e.touches[0].clientY;
      if (!dragging) {
        return;
      }
      ref.current.style.bottom = "unset";
      ref.current.style.right = "unset";
      ref.current.style.top = y - offsetY + "px";
      ref.current.style.left = x - offsetX + "px";
    };
    let dragging = false;
    let offsetX = 0;
    let offsetY = 0;
    const mousedown = (e) => {
      if (!ref.current) {
        return;
      }
      const x = "clientX" in e ? e.clientX : e.touches[0].clientX;
      const y = "clientY" in e ? e.clientY : e.touches[0].clientY;
      const rect = ref.current.getBoundingClientRect();
      offsetX = Math.abs(x - rect.x);
      offsetY = Math.abs(y - rect.y);
      dragging = true;
    };
    const mouseup = () => {
      dragging = false;
    };
    ref.current?.addEventListener("mousedown", mousedown);
    ref.current?.addEventListener("mouseup", mouseup);
    window.addEventListener("mousemove", cb);
    ref.current?.addEventListener("touchstart", mousedown);
    ref.current?.addEventListener("touchend", mouseup);
    window.addEventListener("touchmove", cb);
    return () => {
      ref.current?.removeEventListener("mousedown", mousedown);
      ref.current?.removeEventListener("mouseup", mouseup);
      window.removeEventListener("mousemove", cb);
      ref.current?.removeEventListener("touchstart", mousedown);
      ref.current?.removeEventListener("touchend", mouseup);
      window.removeEventListener("touchmove", cb);
    };
  }, []);
  return /* @__PURE__ */ React.createElement("div", {
    ref,
    className: "overlay-menu bottom-3 left-3"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "overlay-menu-item w-48 h-48 rounded-full"
  }, colors.map((color, i) => /* @__PURE__ */ React.createElement("button", {
    key: color.className,
    className: classnames(`absolute`, `top-[50%]`, `left-[50%]`, `w-12`, `h-12`, `-m-6`, `focus:outline-none`, `focus:ring-2`, `border-4`, `rounded-full`, state.color === color ? "border-white" : "border-transparent", `${color.className}`),
    style: {
      transform: `rotate(${angle * i}deg) translate(${dist}px) rotate(-${angle * i}deg)`
    },
    title: color.name,
    onClick: () => {
      setState({color});
    },
    onMouseDownCapture: (e) => {
      e.stopPropagation();
    }
  })), !!history.length && /* @__PURE__ */ React.createElement("button", {
    className: classnames(`absolute`, `top-[50%]`, `left-[50%]`, `w-12`, `h-12`, `-m-6`, `focus:outline-none`, `focus:ring-2`, `rounded-full`),
    disabled: !history.length,
    title: "Undo",
    onClick: (e) => {
      setHistory((history2) => initial(history2));
    },
    onMouseDownCapture: (e) => {
      e.stopPropagation();
    }
  }, /* @__PURE__ */ React.createElement(FontAwesomeIcon, {
    icon: faUndoAlt
  }))));
};
export default Pallete;
