import React, {useEffect} from "../_snowpack/pkg/react.js";
import {RecoilRoot} from "../_snowpack/pkg/recoil.js";
import Canvas from "./Canvas.js";
import MadeBy from "./MadeBy.js";
import Pallete from "./Pallete.js";
const App = ({}) => {
  useEffect(() => {
  }, []);
  return /* @__PURE__ */ React.createElement(RecoilRoot, null, /* @__PURE__ */ React.createElement("div", {
    className: "relative h-full"
  }, /* @__PURE__ */ React.createElement(Pallete, null), /* @__PURE__ */ React.createElement(Canvas, {
    imgUrl: "/images/mickey.png"
  }), /* @__PURE__ */ React.createElement(MadeBy, null)));
};
export default App;
