import {cloneDeep, isEqual, last} from "../../_snowpack/pkg/lodash-es.js";
import {useEffect, useRef, useState} from "../../_snowpack/pkg/react.js";
import {useRecoilState} from "../../_snowpack/pkg/recoil.js";
import {CanvasHistoryState} from "../state/canvas.js";
import {Colors} from "../state/pallete.js";
import {
  floodFill,
  getImageData,
  getImageDataIndex,
  getPixel,
  paintPixel,
  putImageData
} from "../utils/canvas.js";
export const useColoringCanvas = (containerRef, canvasRef) => {
  const [history, setHistory] = useRecoilState(CanvasHistoryState);
  const [isLoading, setIsLoading] = useState(true);
  const regions = useRef([]);
  const loadImage = (imgUrl) => {
    setIsLoading(true);
    const canvas = canvasRef.current;
    const container = containerRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const {width: maxWidth, height: maxHeight} = container.getBoundingClientRect();
    const img = new Image();
    img.addEventListener("load", (e) => {
      const ratio = img.width / img.height;
      let width = img.width;
      let height = img.height;
      if (width > maxWidth) {
        width = maxWidth;
        height = width / ratio;
      }
      if (height > maxHeight) {
        height = maxHeight;
        width = height * ratio;
      }
      canvas.width = width + 2;
      canvas.height = height + 2;
      ctx.fillStyle = `rgba(${Colors.WHITE.rgb[0]}, ${Colors.WHITE.rgb[1]},${Colors.WHITE.rgb[2]}, 1)`;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 1, 1, canvas.width, canvas.height);
      container.style.backgroundColor = "unset";
      regions.current = [];
      setHistory([]);
      setIsLoading(false);
    }, false);
    requestAnimationFrame(() => {
      img.src = imgUrl;
    });
  };
  const findRegion = async (x, y) => {
    console.time("findRegion");
    const canvas = canvasRef.current;
    const index = getImageDataIndex(canvas, x, y);
    const cache = regions.current.find((r) => r.points.has(index));
    if (cache) {
      console.timeEnd("findRegion");
      return cache;
    }
    document.body.style.cursor = "progress";
    const points = await floodFill(canvas, index);
    document.body.style.cursor = "default";
    if (!points) {
      console.timeEnd("findRegion");
      return void 0;
    }
    const region = {
      points,
      color: Colors.WHITE.rgb
    };
    regions.current.push(region);
    console.timeEnd("findRegion");
    return region;
  };
  const paint = async (x, y, color) => {
    console.time("paint");
    const region = await findRegion(x, y);
    if (region && !isEqual(color, region.color)) {
      region.color = color;
      setHistory((history2) => [...history2, [...cloneDeep(regions.current)]]);
    }
    console.timeEnd("paint");
  };
  useEffect(() => {
    console.time("history");
    const previousRegions = regions.current;
    const currentRegions = cloneDeep(last(history) || []);
    const canvas = canvasRef.current;
    const imageData = getImageData(canvas);
    const {data} = imageData;
    const paintRegion = (region) => {
      region.points.forEach((n) => {
        paintPixel(data, n, region.color);
      });
      if (region.points.has(0)) {
        containerRef.current.style.backgroundColor = `rgba(${region.color[0]}, ${region.color[1]},${region.color[2]}, 1)`;
      }
    };
    currentRegions.filter((region) => {
      const point = region.points.values().next().value;
      return !isEqual(getPixel(data, point), region.color);
    }).forEach(paintRegion);
    previousRegions.filter((region) => {
      const point = region.points.values().next().value;
      return !currentRegions.length || !currentRegions.some((r) => r.points.has(point));
    }).map((region) => {
      region.color = Colors.WHITE.rgb;
      return region;
    }).forEach(paintRegion);
    putImageData(canvas, imageData);
    regions.current = currentRegions;
    console.timeEnd("history");
  }, [history]);
  return {
    isLoading,
    loadImage,
    paint
  };
};
