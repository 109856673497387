import {atom, DefaultValue, selector} from "../../_snowpack/pkg/recoil.js";
export const CanvasState = atom({
  key: "canvasState",
  default: {
    history: []
  }
});
export const CanvasHistoryState = selector({
  key: "canvasHistoryState",
  get: ({get}) => get(CanvasState).history,
  set: ({set, get}, newValue) => set(CanvasState, {
    ...get(CanvasState),
    history: newValue instanceof DefaultValue ? [] : newValue
  })
});
