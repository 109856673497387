import {useEffect} from "../../_snowpack/pkg/react.js";
export const useOnWindowResize = (callback, callOnMount = false) => {
  useEffect(() => {
    window.addEventListener("resize", callback);
    return () => {
      window.removeEventListener("resize", callback);
    };
  }, [callback]);
  useEffect(() => {
    callOnMount && callback();
  }, []);
};
