import {atom} from "../../_snowpack/pkg/recoil.js";
export const Colors = {
  RED: {
    name: "Red",
    rgb: [239, 68, 68],
    className: "bg-red-500"
  },
  GREEN: {
    name: "Green",
    rgb: [16, 185, 129],
    className: "bg-green-500"
  },
  BLUE: {
    name: "Blue",
    rgb: [59, 130, 246],
    className: "bg-blue-500"
  },
  YELLOW: {
    name: "Yellow",
    rgb: [245, 158, 11],
    className: "bg-yellow-500"
  },
  PURPLE: {
    name: "Purple",
    rgb: [139, 92, 246],
    className: "bg-purple-500"
  },
  PINK: {
    name: "Pink",
    rgb: [236, 72, 153],
    className: "bg-pink-500"
  },
  INDIGO: {
    name: "Indigo",
    rgb: [99, 102, 241],
    className: "bg-indigo-500"
  },
  GRAY: {
    name: "Gray",
    rgb: [107, 114, 128],
    className: "bg-gray-500"
  },
  WHITE: {
    name: "White",
    rgb: [255, 255, 255],
    className: "bg-white"
  },
  BLACK: {
    name: "Black",
    rgb: [0, 0, 0],
    className: "bg-black"
  }
};
export const PaletteState = atom({
  key: "paletteState",
  default: {
    color: Colors.RED
  }
});
